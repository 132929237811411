
import { defineComponent, onBeforeMount,ref,onMounted } from "vue";
import headerNav from "@/components/header/index.vue";
import footerBar from '@/components/footer/index.vue';
import helper from "@/utils/helper";
export default defineComponent({
  name: "index",
  setup() {
    const mobile = ref(false)
    onBeforeMount(()=>{
      if(helper.is_mobile()){
        mobile.value = true;
      }
      window.onresize = ()=>{
        // location.reload()
      }
    })

    return {
       mobile
    };
  },
  components: {
    headerNav,
    footerBar
  },
});
