
import { defineComponent, onBeforeMount, ref ,onMounted} from "vue";
import { useRoute } from "vue-router";
import helper from "@/utils/helper";
export default defineComponent({
  name: "header",
  setup() {
    const current = ref<string[]>([]);
    const route = useRoute();
    const menuData = ref([
      { name: "首页", key: "index", url: "/" },
      { name: "律所概述", key: "summary", url: "/summary" },
      { name: "核心团队", key: "team", url: "/team" },
      { name: "核心业务", key: "business", url: "/business" },
      { name: "加入我们", key: "joinUs", url: "/joinUs" },
      { name: "联系我们", key: "about", url: "/about" },
    ]);
    const mobile = ref(false);
    const isProduction = ref(false);
    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
      if (process.env.NODE_ENV == "production") {
        isProduction.value = true;
      }

      current.value = [];
      current.value.push(route.meta.key as string);
    });
    
    return {
      current,
      menuData,
      route,
      mobile,
      isProduction,
    };
  },
  components: {},
});
