
import { defineComponent, onBeforeMount, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import helper from "@/utils/helper";

export default defineComponent({
  name: "footerBar",
  setup() {
    const mobile = ref(false);
    
    onBeforeMount(() => {
      if (helper.is_mobile()) {
        mobile.value = true;
      }
    });
    return {
      mobile,
    };
  },
});
